import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { useRemoteData } from '@rchitected/hooks';
import { Modal, useModal } from '@rchitected/ui';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Heading, HeadingFilters, HeadingTitle } from '@/components/Heading';
import { Page } from '@/components/Page';
import { ProjectTile } from '@/components/ProjectTile';
import { projectApi } from '@/integrations/api';
import { CreateProjectModal } from '@/modals/CreateProjectModal';
import { urls } from '@/utils/url';

export const ProjectListPage: React.FC = () => {
  const addModal = useModal(CreateProjectModal);
  const navigate = useNavigate();

  const projects = useRemoteData({ key: 'getProjects' }, async () => projectApi.getProjects().then((r) => r.data.data));

  return (
    <React.Fragment>
      <Page>
        <Heading>
          <HeadingTitle>Projects</HeadingTitle>

          <HeadingFilters />
        </Heading>

        <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {projects.data?.map(({ id, name }) => (
            <li key={id}>
              <Link to={urls.projects.getOne({ projectId: id })}>
                <ProjectTile {...{ name }} />
              </Link>
            </li>
          ))}

          <li className="flex flex-col">
            <button
              className="flex h-full items-center justify-center overflow-hidden rounded-xl border border-gray-200"
              onClick={() =>
                addModal
                  .open({})
                  .then(({ id }) => navigate(urls.projects.getOne({ projectId: id })))
                  .catch(() => void 0)
              }
            >
              <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              <div>Add project</div>
            </button>
          </li>
        </ul>
      </Page>

      <Modal {...addModal.props} />
    </React.Fragment>
  );
};
