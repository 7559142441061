import { ForcieClient } from '@rchitected/forcie-client';
import { useForcieUpdate } from '@rchitected/forcie-react';
import { Language, Locale, getLocale } from '@rchitected/locale';
import flat from 'flat';
import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

import { App } from '@/App';
import { Auth } from '@/containers/useAuth';
import { LoadingPage } from '@/pages/LoadingPage';

import { VERSION } from './config';

const client = new ForcieClient({
  url: 'https://assets-forcie.botondveress.com',
  apiKey: 'MDFKMFBFWks3OEFIQkNIM0YyTVhKNDhEWlovMDFKMFBGMDFCOEhWVjBCTUg4Tk5CRFk0M0Y='
});

const root = createRoot(document.getElementById('app')!);

const AppWithLanguage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [locale, , messages] = Language.useContainer();

  if (!messages.data) return <LoadingPage />;

  return (
    <IntlProvider
      defaultLocale={locale}
      defaultRichTextElements={{ br: (<br />) as any }}
      messages={flat(messages.data)}
      {...{ locale }}
    >
      {children}
    </IntlProvider>
  );
};

const AppWithForcie: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [{ loading, updateRequired, error }] = useForcieUpdate({ client, version: VERSION }, []);

  if (loading) return <LoadingPage />;

  if (updateRequired) {
    alert('Update required.');
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

function bootstrapApplication(element: Root) {
  element.render(
    <StrictMode>
      <AppWithForcie>
        <BrowserRouter>
          <Language.Provider initialState={{ locale: getLocale(Locale.English) }}>
            <AppWithLanguage>
              <Auth.Provider>
                <App />
              </Auth.Provider>
            </AppWithLanguage>
          </Language.Provider>
        </BrowserRouter>
      </AppWithForcie>
    </StrictMode>
  );
}

bootstrapApplication(root);
