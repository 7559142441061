import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';

interface LoadingProps {
  visible: boolean;
  className?: string;
}

export const Loading = React.forwardRef<HTMLDivElement, React.PropsWithChildren<LoadingProps>>(
  ({ visible, className, children }, ref) => (
    <Transition
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={visible}
      {...{ ref, children, className }}
    />
  )
);

interface LoadingIndicatorProps {
  className?: string;
}

export const LoadingIndicator = React.forwardRef<SVGSVGElement, React.PropsWithChildren<LoadingIndicatorProps>>(
  ({ className }, ref) => (
    <svg
      {...{ ref }}
      className={classNames('block animate-spin', className)}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      />
    </svg>
  )
);

interface LoadingMessageProps {
  center?: boolean;
  className?: string;
}

export const LoadingMessage = React.forwardRef<HTMLDivElement, React.PropsWithChildren<LoadingMessageProps>>(
  ({ center, className, children }, ref) => (
    <div
      {...{ ref }}
      className={classNames(
        'flex select-none items-center gap-2 font-semibold',
        { 'justify-center': center },
        className
      )}
    >
      {children}
    </div>
  )
);

interface LoadingOverlayProps {
  className?: string;
}

export const LoadingOverlay = React.forwardRef<HTMLDivElement, React.PropsWithChildren<LoadingOverlayProps>>(
  (props, ref) => (
    <div
      {...{ ref }}
      {...props}
      className={classNames('flex items-center justify-center bg-white bg-opacity-90', props.className)}
    />
  )
);
