import { useRemoteData } from '@rchitected/hooks';
import { updateApiAccessToken } from '@rchitected/utility';
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import React from 'react';
import { createContainer } from 'unstated-next';

import { contextApi } from '@/integrations/api';
import { auth } from '@/integrations/firebase';

const useAuth = () => {
  const getAccessToken = useRemoteData({ key: 'getAccessToken' }, async () => {
    await auth.authStateReady();
    return auth.currentUser?.getIdToken();
  });

  const accessToken = auth.currentUser ? getAccessToken.data : undefined;
  const authenticated = !!accessToken;

  React.useEffect(() => {
    const unsub1 = onIdTokenChanged(auth, () => getAccessToken.mutate());
    const unsub2 = onAuthStateChanged(auth, () => getAccessToken.mutate());

    return () => {
      unsub1();
      unsub2();
    };
  }, []);

  const login = React.useCallback(
    ({ email, password }: { email: string; password: string }) => signInWithEmailAndPassword(auth, email, password),
    []
  );

  const register = React.useCallback(
    ({ email, password }: { email: string; password: string }) => createUserWithEmailAndPassword(auth, email, password),
    []
  );

  const logout = React.useCallback(() => signOut(auth), []);

  updateApiAccessToken(accessToken, () => {
    if (!auth.currentUser) throw new Error(`No current user.`);
    return auth.currentUser.getIdToken(true);
  });

  const context = useRemoteData({ key: 'getContext', skip: !authenticated }, async () =>
    contextApi.get().then((r) => r.data)
  );

  const loading = getAccessToken.isLoading || context.isLoading;

  return React.useMemo(
    () => ({ authenticated, loading, context: context.data, login, register, logout }),
    [loading, authenticated, context, login, register, logout]
  );
};

export const Auth = createContainer(useAuth);
