/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: botond.veress@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ContextResponse
 */
export interface ContextResponse {
    /**
     * 
     * @type {User}
     * @memberof ContextResponse
     */
    'user': User;
    /**
     * 
     * @type {Array<UserOrganization>}
     * @memberof ContextResponse
     */
    'organizations': Array<UserOrganization>;
}
/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'name': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof CreateProjectRequest
     */
    'type': ProjectType;
}


/**
 * 
 * @export
 * @interface CreateVersionRequest
 */
export interface CreateVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVersionRequest
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CreateVersionRequest
     */
    'rollout': number;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof Project
     */
    'type': ProjectType;
}


/**
 * 
 * @export
 * @interface ProjectDetail
 */
export interface ProjectDetail {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetail
     */
    'name': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectDetail
     */
    'type': ProjectType;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetail
     */
    'apiKey': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export enum ProjectType {
    React = 'react'
}


/**
 * 
 * @export
 * @interface ProjectVersion
 */
export interface ProjectVersion {
    /**
     * 
     * @type {string}
     * @memberof ProjectVersion
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectVersion
     */
    'rollout': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectVersion
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface ProjectVersionsResponse
 */
export interface ProjectVersionsResponse {
    /**
     * 
     * @type {Array<ProjectVersion>}
     * @memberof ProjectVersionsResponse
     */
    'data': Array<ProjectVersion>;
}
/**
 * 
 * @export
 * @interface ProjectsResponse
 */
export interface ProjectsResponse {
    /**
     * 
     * @type {Array<Project>}
     * @memberof ProjectsResponse
     */
    'data': Array<Project>;
}
/**
 * 
 * @export
 * @interface UpdateVersionRequest
 */
export interface UpdateVersionRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateVersionRequest
     */
    'rollout': number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserOrganization
 */
export interface UserOrganization {
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'name': string;
}

/**
 * ContextApi - axios parameter creator
 * @export
 */
export const ContextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the session context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/context`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContextApi - functional programming interface
 * @export
 */
export const ContextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContextApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the session context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContextApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContextApi - factory interface
 * @export
 */
export const ContextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContextApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the session context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: RawAxiosRequestConfig): AxiosPromise<ContextResponse> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContextApi - object-oriented interface
 * @export
 * @class ContextApi
 * @extends {BaseAPI}
 */
export class ContextApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the session context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextApi
     */
    public get(options?: RawAxiosRequestConfig) {
        return ContextApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create project.
         * @param {CreateProjectRequest} [createProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (createProjectRequest?: CreateProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create version.
         * @param {string} projectId The id of the project.
         * @param {CreateVersionRequest} [createVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion: async (projectId: string, createVersionRequest?: CreateVersionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createVersion', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/versions`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the project.
         * @param {string} projectId The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the project.
         * @param {string} projectId The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the projects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the version.
         * @param {string} projectId The id of the project.
         * @param {string} versionId The id of the version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (projectId: string, versionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getVersion', 'projectId', projectId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getVersion', 'versionId', versionId)
            const localVarPath = `/projects/{projectId}/versions/{versionId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the versions.
         * @param {string} projectId The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getVersions', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/versions`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the project.
         * @param {string} projectId The id of the project.
         * @param {CreateProjectRequest} [createProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: string, createProjectRequest?: CreateProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the version.
         * @param {string} projectId The id of the project.
         * @param {string} versionId The id of the version.
         * @param {UpdateVersionRequest} [updateVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion: async (projectId: string, versionId: string, updateVersionRequest?: UpdateVersionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateVersion', 'projectId', projectId)
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('updateVersion', 'versionId', versionId)
            const localVarPath = `/projects/{projectId}/versions/{versionId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create project.
         * @param {CreateProjectRequest} [createProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(createProjectRequest?: CreateProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(createProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create version.
         * @param {string} projectId The id of the project.
         * @param {CreateVersionRequest} [createVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVersion(projectId: string, createVersionRequest?: CreateVersionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVersion(projectId, createVersionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes the project.
         * @param {string} projectId The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.deleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the project.
         * @param {string} projectId The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the projects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the version.
         * @param {string} projectId The id of the project.
         * @param {string} versionId The id of the version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(projectId: string, versionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(projectId, versionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the versions.
         * @param {string} projectId The id of the project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersions(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectVersionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersions(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getVersions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the project.
         * @param {string} projectId The id of the project.
         * @param {CreateProjectRequest} [createProjectRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: string, createProjectRequest?: CreateProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, createProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the version.
         * @param {string} projectId The id of the project.
         * @param {string} versionId The id of the version.
         * @param {UpdateVersionRequest} [updateVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVersion(projectId: string, versionId: string, updateVersionRequest?: UpdateVersionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVersion(projectId, versionId, updateVersionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create project.
         * @param {ProjectsApiCreateProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(requestParameters: ProjectsApiCreateProjectRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetail> {
            return localVarFp.createProject(requestParameters.createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create version.
         * @param {ProjectsApiCreateVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion(requestParameters: ProjectsApiCreateVersionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectVersion> {
            return localVarFp.createVersion(requestParameters.projectId, requestParameters.createVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the project.
         * @param {ProjectsApiDeleteProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(requestParameters: ProjectsApiDeleteProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProject(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the project.
         * @param {ProjectsApiGetProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(requestParameters: ProjectsApiGetProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetail> {
            return localVarFp.getProject(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the projects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: RawAxiosRequestConfig): AxiosPromise<ProjectsResponse> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the version.
         * @param {ProjectsApiGetVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(requestParameters: ProjectsApiGetVersionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectVersion> {
            return localVarFp.getVersion(requestParameters.projectId, requestParameters.versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the versions.
         * @param {ProjectsApiGetVersionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersions(requestParameters: ProjectsApiGetVersionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectVersionsResponse> {
            return localVarFp.getVersions(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the project.
         * @param {ProjectsApiUpdateProjectRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(requestParameters: ProjectsApiUpdateProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDetail> {
            return localVarFp.updateProject(requestParameters.projectId, requestParameters.createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the version.
         * @param {ProjectsApiUpdateVersionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(requestParameters: ProjectsApiUpdateVersionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectVersion> {
            return localVarFp.updateVersion(requestParameters.projectId, requestParameters.versionId, requestParameters.updateVersionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProject operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateProjectRequest
 */
export interface ProjectsApiCreateProjectRequest {
    /**
     * 
     * @type {CreateProjectRequest}
     * @memberof ProjectsApiCreateProject
     */
    readonly createProjectRequest?: CreateProjectRequest
}

/**
 * Request parameters for createVersion operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateVersionRequest
 */
export interface ProjectsApiCreateVersionRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiCreateVersion
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateVersionRequest}
     * @memberof ProjectsApiCreateVersion
     */
    readonly createVersionRequest?: CreateVersionRequest
}

/**
 * Request parameters for deleteProject operation in ProjectsApi.
 * @export
 * @interface ProjectsApiDeleteProjectRequest
 */
export interface ProjectsApiDeleteProjectRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiDeleteProject
     */
    readonly projectId: string
}

/**
 * Request parameters for getProject operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRequest
 */
export interface ProjectsApiGetProjectRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiGetProject
     */
    readonly projectId: string
}

/**
 * Request parameters for getVersion operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetVersionRequest
 */
export interface ProjectsApiGetVersionRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiGetVersion
     */
    readonly projectId: string

    /**
     * The id of the version.
     * @type {string}
     * @memberof ProjectsApiGetVersion
     */
    readonly versionId: string
}

/**
 * Request parameters for getVersions operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetVersionsRequest
 */
export interface ProjectsApiGetVersionsRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiGetVersions
     */
    readonly projectId: string
}

/**
 * Request parameters for updateProject operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateProjectRequest
 */
export interface ProjectsApiUpdateProjectRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiUpdateProject
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateProjectRequest}
     * @memberof ProjectsApiUpdateProject
     */
    readonly createProjectRequest?: CreateProjectRequest
}

/**
 * Request parameters for updateVersion operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateVersionRequest
 */
export interface ProjectsApiUpdateVersionRequest {
    /**
     * The id of the project.
     * @type {string}
     * @memberof ProjectsApiUpdateVersion
     */
    readonly projectId: string

    /**
     * The id of the version.
     * @type {string}
     * @memberof ProjectsApiUpdateVersion
     */
    readonly versionId: string

    /**
     * 
     * @type {UpdateVersionRequest}
     * @memberof ProjectsApiUpdateVersion
     */
    readonly updateVersionRequest?: UpdateVersionRequest
}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Create project.
     * @param {ProjectsApiCreateProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(requestParameters: ProjectsApiCreateProjectRequest = {}, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(requestParameters.createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create version.
     * @param {ProjectsApiCreateVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createVersion(requestParameters: ProjectsApiCreateVersionRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createVersion(requestParameters.projectId, requestParameters.createVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the project.
     * @param {ProjectsApiDeleteProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(requestParameters: ProjectsApiDeleteProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the project.
     * @param {ProjectsApiGetProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(requestParameters: ProjectsApiGetProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProject(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the projects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the version.
     * @param {ProjectsApiGetVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getVersion(requestParameters: ProjectsApiGetVersionRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getVersion(requestParameters.projectId, requestParameters.versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the versions.
     * @param {ProjectsApiGetVersionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getVersions(requestParameters: ProjectsApiGetVersionsRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getVersions(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the project.
     * @param {ProjectsApiUpdateProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(requestParameters: ProjectsApiUpdateProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(requestParameters.projectId, requestParameters.createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the version.
     * @param {ProjectsApiUpdateVersionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateVersion(requestParameters: ProjectsApiUpdateVersionRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateVersion(requestParameters.projectId, requestParameters.versionId, requestParameters.updateVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



