import classNames from 'classnames';
import React from 'react';

import { Card } from './Card';
import { ProjectDetails } from './ProjectDetails';

interface Props extends React.ComponentProps<typeof ProjectDetails> {
  version: string;
  updatedAt: Date;
}

export const ProjectTile: React.FC<Props> = ({ name, image, version, updatedAt, className }) => (
  <Card className={classNames('overflow-hidden', className)}>
    <ProjectDetails {...{ name, image }} className="border-b border-gray-900/5 p-6">
      hello
    </ProjectDetails>
  </Card>
);
