import { projectApi } from '@/integrations/api';
import { Project, ProjectType } from '@rchitected/api';
import { translations } from '@rchitected/locale';
import {
  Form,
  InputWithLabel,
  InstanceProps,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  Select,
  SubmitError,
  ValidatedField
} from '@rchitected/ui';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

interface Props extends InstanceProps<Project> {}

const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.project.name.label),
    type: yup
      .mixed<ProjectType>()
      .oneOf(Object.values(ProjectType))
      .required()
      .label(translations.fields.project.type.label)
  })
  .required();

export const CreateProjectModal = React.forwardRef<HTMLDivElement, Props>(
  ({ initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const onSubmit = React.useCallback(
      async ({ name, type }: yup.InferType<typeof schema>) => {
        const project = await projectApi.createProject({ createProjectRequest: { name, type } }).then((r) => r.data);
        await onAction(project);
      },
      [onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.createProject.title} />
          </ModalHeader>

          <div className="text-sm text-gray-400">
            <FormattedMessage id={translations.modals.createProject.message} />
          </div>

          <Form {...{ schema, onSubmit }}>
            {({ submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  ref={initialFocus}
                  field={InputWithLabel}
                  id="name"
                  label={<FormattedMessage id={translations.fields.project.name.label} />}
                  name="name"
                  placeholder={intl.formatMessage({ id: translations.fields.project.name.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  as={Select}
                  field={InputWithLabel}
                  id="type"
                  items={Object.values(ProjectType).map((value) => ({ name: value, value })) ?? []}
                  label={<FormattedMessage id={translations.fields.project.type.label} />}
                  name="type"
                  placeholder={intl.formatMessage({ id: translations.fields.project.type.placeholder })}
                  readOnly={!!submitting}
                />

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse gap-2">
                  <ModalPrimaryButton onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.buttons.create} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);
