import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';

export interface SelectItemProps {
  active?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler;
}

export const SelectItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<SelectItemProps>>(
  ({ active, isSelected, disabled, className, onClick, children, ...rest }, ref) => (
    <li
      {...rest}
      {...{ ref }}
      className={classNames(
        `group relative select-none py-2 pl-2 pr-9`,
        isSelected ? 'bg-gray-900 text-white' : active && 'bg-gray-900 text-white',
        disabled ? `cursor-default` : `cursor-pointer`,
        className
      )}
      onClick={disabled ? undefined : (event) => onClick?.(event)}
    >
      <span className="block truncate text-sm">{children}</span>

      {isSelected && (
        <span aria-hidden="true" className="absolute inset-y-0 right-0 flex items-center pr-2">
          <CheckIcon className="h-5 w-5" />
        </span>
      )}
    </li>
  )
);
