import { compareVersions } from 'compare-versions';

import { IStorage, WebStorage } from './storage';

interface ForcieVersion {
  v: string;
  r: number;
}

interface ForcieVersionResponse {
  versions: ForcieVersion[];
}

export interface IForcieClient {
  checkForUpdates(version: string): Promise<boolean>;
}

export interface ForcieClientOptions {
  apiKey: string;
  url?: string;
  storage?: IStorage;
}

export class ForcieClient implements IForcieClient {
  private readonly apiKey: string;
  private readonly url: string;
  private readonly storage: IStorage;
  private readonly cacheKey = 'forcie';
  private readonly identifier: Promise<number>;

  constructor({ apiKey, url, storage }: ForcieClientOptions) {
    this.apiKey = apiKey;
    this.url = url ?? `http://localhost:3000`;
    this.storage = storage ?? new WebStorage();

    this.identifier = this.getCachedIdentifier().then(
      async (identifier) => identifier ?? (await this.createIdentifier())
    );
  }

  async checkForUpdates(current: string): Promise<boolean> {
    const [identifier, data] = await Promise.all([this.identifier, this.load()]);

    return data.versions.some(
      (version) => compareVersions(version.v, current) < 0 && this.includedInRollout(identifier, version.r)
    );
  }

  private async load(): Promise<ForcieVersionResponse> {
    const response = await fetch(`${this.url}/${this.apiKey}/versions.json`);
    return await response.json();
  }

  private includedInRollout(identifier: number, rollout: number) {
    return identifier / 0xffffffffff <= rollout;
  }

  private async getCachedIdentifier(): Promise<number | undefined> {
    const cached = await this.storage.get(this.cacheKey);

    if (!cached) return;

    try {
      const parsed = JSON.parse(cached);
      return isNaN(parsed) ? undefined : parsed;
    } catch {
      return;
    }
  }

  private async createIdentifier() {
    const identifier = this.getIdentifier();

    await this.storage.set(this.cacheKey, JSON.stringify(identifier));

    return identifier;
  }

  private getIdentifier() {
    const array = new Uint8Array(5);

    crypto.getRandomValues(array);

    return parseInt(
      '0x' +
        Array.from(array)
          .map((c) => c.toString(16))
          .join(''),
      16
    );
  }
}
