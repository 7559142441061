import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { urls } from '@/utils/url';

import { ProjectListPage } from '@/pages/ProjectListPage';
import { ProjectDetailPage } from './pages/ProjectDetailPage';

export const PrivateRoutes: React.FC = () => (
  <Routes>
    <Route element={<ProjectListPage />} path={urls.projects.root} />
    <Route element={<ProjectDetailPage />} path={urls.projects.one} />
    <Route element={<ProjectDetailPage />} path={urls.projects.new} />
    <Route element={<Navigate replace to={urls.projects.root} />} path="*" />
  </Routes>
);
