import { IForcieClient } from '@rchitected/forcie-client';
import React from 'react';

export interface UseForcieUpdateOptions {
  client: IForcieClient;
  version: string;
}

export interface UseForcieUpdateState {
  loading: boolean;
  updateRequired?: boolean;
  error?: Error;
}

export const useForcieUpdate = ({ client, version }: UseForcieUpdateOptions, deps: React.DependencyList) => {
  const [state, setState] = React.useState<UseForcieUpdateState>({ loading: true });

  const check = React.useCallback(async () => {
    setState((state) => ({ ...state, loading: true }));

    try {
      const updateRequired = await client.checkForUpdates(version);
      setState({ loading: false, updateRequired, error: undefined });
      return updateRequired;
    } catch (error) {
      setState({ loading: false, updateRequired: undefined, error: error as Error });
      throw error;
    }
  }, [...deps, version]);

  React.useEffect(() => {
    const timeout = setTimeout(() => check().catch(() => void 0), 0);

    return () => clearTimeout(timeout);
  }, [check]);

  return React.useMemo(() => [state, check] as const, [state, check]);
};
