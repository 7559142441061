export interface IStorage {
  get(key: string): Promise<string | undefined>;
  set(key: string, value?: string): Promise<void>;
}

export interface WebStorageOptions {
  prefix?: string;
}

export class WebStorage implements IStorage {
  private prefix: string;

  constructor({ prefix }: WebStorageOptions = {}) {
    this.prefix = prefix ?? 'forcie.';
  }

  private getKey(key: string) {
    return `forcie.${key}`;
  }

  async get(key: string): Promise<string | undefined> {
    return localStorage.getItem(this.getKey(key)) ?? undefined;
  }

  async set(key: string, value?: string): Promise<void> {
    if (value) localStorage.setItem(this.getKey(key), value);
    else localStorage.removeItem(this.getKey(key));
  }
}
