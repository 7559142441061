import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, browserSessionPersistence, initializeAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDxUtFBvHKVezjqeQM44I4UDpfhZOXJ5lE',
  authDomain: 'forcie-45a92.firebaseapp.com',
  projectId: 'forcie-45a92',
  storageBucket: 'forcie-45a92.appspot.com',
  messagingSenderId: '586225243455',
  appId: '1:586225243455:web:5435da6187dace4c916f8a'
};

const app = initializeApp(firebaseConfig);

// app.automaticDataCollectionEnabled = false;

export const auth = initializeAuth(app, {
  persistence: [browserLocalPersistence, browserSessionPersistence]
});
